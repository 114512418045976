import gsap from './lib/gsap.min.js';
import { ScrollTrigger } from './lib/ScrollTrigger.min.js';
import eqHeight from './lib/eq.js';
import { ScrollToPlugin } from './lib/ScrollToPlugin.min.js';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const init = () => {

    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      }





        


        const resizeHandler = () => {

          if(window.innerWidth >= 1024){
            gsap.set('.stagger-block', { x:window.innerWidth });
          } else {
            gsap.set('.stagger-block', { x:0 });            
          }

        }

        resizeHandler();
        window.addEventListener("resize", resizeHandler);





      ScrollTrigger.matchMedia({
	
        // large
        "(min-width: 1024px)": function() {
          // setup animations and ScrollTriggers for screens 960px wide or greater...
          // These ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
          console.log("DESKTOP");

          const anim = gsap.to(".stagger-block", {
            duration: 1,
            stagger: 0.08,
            ease: 'power4.out',
            x:0
          });
          
          
          ScrollTrigger.create({
            trigger: "#staggerBlocks",
            start: "top bottom",
            end: "center center",
            animation: anim,
            scrub:true
          });




          //end desktop section
        },
      
        // small
        "(max-width: 599px)": function() {
          // The ScrollTriggers created inside these functions are segregated and get
          // reverted/killed when the media query doesn't match anymore. 
          console.log("MOBILE");

        // end mobile section
        },
        
        // all 
        "all": function() {
          // ScrollTriggers created here aren't associated with a particular media query,
          // so they persist.




          const herosection = document.getElementsByClassName('hero')[0];
      
          herosection.addEventListener('click', (event) => {
            //alert('CLICKED');
            gsap.to(window, {duration: 2, scrollTo:"#intro", ease: "power2.inOut"});
          });      
    
           
    
        gsap.set('.hero', {autoAlpha:0});
        gsap.set('.item', {autoAlpha:0});
    
    
        var introtl = gsap.timeline({repeat: 0, repeatDelay: 0});
    introtl.to(".hero", {autoAlpha: 1, duration: 2, delay:0, ease: "power2"});
    introtl.to(".item", {autoAlpha: 1, duration: 1, ease: "power2"});
    
    
    
    gsap.from(".chevron", {
      opacity: 1,
      ease: "power2",
      scrollTrigger: {
        trigger: '.hero',
        scrub: true,
        markers: false,
        start:'center center',
        end: 'bottom top',
        toggleActions: "play none none reverse"
      }
    });





          const fadeinups = gsap.utils.toArray('.fadeinup');

          fadeinups.forEach((fadeinup, i) => {
          const fadeinupanim = gsap.fromTo(fadeinup, {autoAlpha: 0, y: 10}, {duration: 1, autoAlpha: 1, y: 0, ease: "power2"});
          ScrollTrigger.create({
              trigger: fadeinup,
              animation: fadeinupanim,
              scrub:true,
              start: 'bottom bottom',
              end: 'center center',
              markers:false,
              toggleActions: 'play none none reverse'
          });
          });
      
      





          const fadeins = gsap.utils.toArray('.fadein');
      
          fadeins.forEach((fadein, i) => {
          const fadeinanim = gsap.fromTo(fadein, {autoAlpha: 0}, {duration: 1.2, autoAlpha: 1, ease: "power2"});
          ScrollTrigger.create({
              trigger: fadein,
              animation: fadeinanim,
              scrub:true,
              start: 'top bottom',
              toggleActions: 'play none none reverse'
          });
          });
      





      
          const fadeinfooterss = gsap.utils.toArray('.fadeinfooter');
      
          fadeinfooterss.forEach((fadein, i) => {
          const fadeinfooteranim = gsap.fromTo(fadein, {autoAlpha: 0}, {duration: 1.2, autoAlpha: 1, ease: "power2"});
          ScrollTrigger.create({
              trigger: fadein,
              animation: fadeinfooteranim,
              scrub:false,
              start: 'bottom bottom',
              toggleActions: 'play none none reverse'
          });
          });








          gsap.utils.toArray('.section').forEach((section, i) => {
  
            if(section.getAttribute('data-color') !== null) {
              
              var colorAttr = section.getAttribute('data-color')
              
              gsap.to(".logo .colorchange", {
                fill: colorAttr === "dark" ? "#003122" : "#CCFF00",
                immediateRender: false,
                duration:0.1,
                ease: "power2",
                scrollTrigger: {
                  trigger: section,
                  scrub: false,
                  markers: false,
                  start:'-=50',
                  end: 'bottom',
                  toggleActions: "play none none reverse"
                }
              });
          
            }
            
          });










          //end all section
        }
        
      }); 



















    

    eqHeight();

}

init();

//export default init;